<template>
  <div class="image-view-big-wrapper" >
    <div class="content">
      <div class="pic" >
        <img
            :src="url"
            :style="'transform: scale('+imgScale+') rotate('+ imgRotate +'deg)'"

        />
      </div>
    </div>
    <div class="el-image-viewer-close" @click="handlerCloseMask">
      <i class="el-icon-close"></i>
    </div>
    <div class="control-wrapper">
      <div class="el-image-viewer" >
        <i class="el-icon-zoom-out" @click.stop="handlerScaleImg(-1)"></i>
        <i class="el-icon-zoom-in" @click.stop="handlerScaleImg(1)"></i>
        <i class="el-icon-refresh-left" @click.stop="handlerRotateImg(-90)"></i>
        <i class="el-icon-refresh-right" @click.stop="handlerRotateImg(90)"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageViewBig",
  data() {
    return {
      imgMoveX:0,
      imgMoveY:0,
      url: "",
      imgScale:1,
      imgRotate:0,
    }
  },
  methods: {

    handlerRotateImg(rotateV){
      let { imgRotate } = this
      imgRotate += rotateV
      if(imgRotate===-360 ||imgRotate===360 ){
        imgRotate = 0
      }

      this.imgRotate = imgRotate
    },
    handlerScaleImg(scaleV){
      let { imgScale } = this
      imgScale = (imgScale*10 + scaleV ) / 10
      if(imgScale===0.1)return
      this.imgScale = imgScale
    },
    handlerCloseMask() {
      this.imgRotate = 0
      this.imgScale = 1
      this.$viewImage.viewHide()
    }
  }
}
</script>

<style scoped lang="scss">
.image-view-big-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .8);
  z-index: 10000000;

  .pic {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;

    }
  }

  .el-image-viewer-close {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: #fff;
    background-color: #606266;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: .8;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;

  }
  .control-wrapper {
    left: 50%;
    bottom: 80px;
    transform: translateX(-50%);
    width: 282px;
    height: 44px;
    padding: 0 23px;
    background-color: #606266;
    border-color: #fff;
    border-radius: 22px;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .8;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
    .el-image-viewer {
      width: 100%;
      height: 100%;
      text-align: justify;
      cursor: default;
      font-size: 23px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      i {
        font-family: element-icons!important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: baseline;
        display: inline-block;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
      }
    }
  }
}
</style>
