import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


//挂载全局图片查看器
const imageViewCom = Vue.extend(imageView)
// 创建 Profile 实例，并挂载到一个元素上。
const imageViewInstance = new imageViewCom().$mount()

const viewImage = {
  viewShow(src){
    imageViewInstance.url = src
    document.body.appendChild(imageViewInstance.$el)
  },
  viewHide(){
    document.body.removeChild(imageViewInstance.$el)
  },}
Vue.prototype.$viewImage = viewImage
//引入公共css
import '@/assets/style/reset.css'
import '@/assets/style/home.css'
import '@/assets/style/flex.scss'
import '@/assets/style/publicCss.css'
//引入element-ui
import ElementUI from 'element-ui'
Vue.use(ElementUI)
import 'element-ui/lib/theme-chalk/index.css'
//图片懒加载
import VueLazyload from "vue-lazyload"
Vue.use(VueLazyload,{
  preLoad: 1.5,
  error: require('./assets/images/errorLoading.png'),
  loading: require('./assets/images/imgLoading.gif'),
  attempt: 1
})


Vue.config.productionTip = false

import imageView from "@/components/ImageViewBig"






new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
