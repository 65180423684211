<template>
  <div id="app">
    <router-view></router-view>

  </div>

</template>

<script>
import { getScrollTop } from "@/utils/sys-ui";

export default {
  name: "app",
  mounted() {
    window.addEventListener('scroll',()=>{ this.$store.commit('SET_SCROLLTOP',getScrollTop()) })

  }
};
</script>
<style>
#app{
  background-color: whitesmoke;
}
</style>
