import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        bannerList: [],
        sonCompanyLogo: undefined,
        //官网bannerData数据
        websiteBannerData: undefined,

        scrollTop:0
    },
    mutations: {
        setBannerList(state, data) {
            state.bannerList = data
        },
        setSonCompanyLogo(state, data) {
            state.sonCompanyLogo = data
        },

        //设置官网banner数据
        setWebsiteBannerData(state,data){
            state.websiteBannerData  = data
        },
        //获取文档高度
        SET_SCROLLTOP: (state, heightValue) => {
            state.scrollTop = heightValue
        },
    },
    actions: {

    },
    modules: {}
})
